import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Preview from 'components/Preview';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "this-step-takes-what-weve-built-so-far-and-optimizes-the-app-for-a-production-environment-well-be-deploying-the-production-build-to-ibm-cloud"
    }}>{`This step takes what we’ve built so far and optimizes the app for a production environment. We’ll be deploying the production build to IBM Cloud.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Fork, clone and branch</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Create IBM Cloud account</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Optimize Sass</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Build for production</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Create manifest file</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Create static file</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Deploy app</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Submit pull request</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "preview"
    }}>{`Preview`}</h2>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://react-step-6--carbon-tutorial.netlify.com"
      }}>{`preview`}</a>{` of what you’ll build (visually no different, but built for production):`}</p>
    <Preview height="400" title="Carbon Tutorial Step 5" src="https://react-step-6--carbon-tutorial.netlify.com" frameBorder="no" allowtransparency="true" allowFullScreen="true" className="bx--iframe bx--iframe--border" mdxType="Preview" />
    <h2 {...{
      "id": "fork-clone-and-branch"
    }}>{`Fork, clone and branch`}</h2>
    <p>{`This tutorial has an accompanying GitHub repository called `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial"
      }}>{`carbon-tutorial`}</a>{` that we’ll use as a starting point for each step. If you haven’t forked and cloned that repository yet, and haven’t added the upstream remote, go ahead and do so by following the `}<a parentName="p" {...{
        "href": "/tutorial/react/step-1#fork-clone--branch"
      }}>{`step 1 instructions`}</a>{`.`}</p>
    <h3 {...{
      "id": "branch"
    }}>{`Branch`}</h3>
    <p>{`With your repository all set up, let’s check out the branch for this tutorial step’s starting point.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git fetch upstream
$ git checkout -b react-step-5 upstream/react-step-5
`}</code></pre>
    <p><em parentName="p">{`Note: This builds on top of step 4, but be sure to check out the upstream step 5 branch because it includes the static assets required to get through this step.`}</em></p>
    <h3 {...{
      "id": "build-and-start-app"
    }}>{`Build and start app`}</h3>
    <p>{`Install the app’s dependencies (in case you’re starting fresh in your current directory and not continuing from the previous step):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn
`}</code></pre>
    <p>{`Then, start the app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn start
`}</code></pre>
    <p>{`You should see something similar to where the `}<a parentName="p" {...{
        "href": "/tutorial/react/step-4"
      }}>{`previous step`}</a>{` left off.`}</p>
    <h2 {...{
      "id": "create-ibm-cloud-account"
    }}>{`Create IBM Cloud account`}</h2>
    <p>{`Before we get started, `}<a parentName="p" {...{
        "href": "https://cloud.ibm.com/registration"
      }}>{`create an IBM Cloud account`}</a>{` if you don’t already have one, as we’ll be deploying there in a bit.`}</p>
    <h2 {...{
      "id": "optimize-sass"
    }}>{`Optimize Sass`}</h2>
    <p>{`So far we’ve been developing in a, well, development environment where static asset optimization hasn’t been a priority. If you reference `}<inlineCode parentName="p">{`/src/index.scss`}</inlineCode>{`, you’ll see one `}<inlineCode parentName="p">{`@import`}</inlineCode>{` that is pulling in Carbon’s full Sass build.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss",
        "metastring": "path=src/index.scss",
        "path": "src/index.scss"
      }}>{`$feature-flags: (
  ui-shell: true,
  grid-columns-16: true,
);

@import 'carbon-components/scss/globals/scss/styles.scss';
`}</code></pre>
    <p>{`To give you an idea of what’s all included, open up `}<inlineCode parentName="p">{`node_modules/carbon-components/scss/globals/scss/styles.scss`}</inlineCode>{`. You’ll see imports for components like accordion, slider, tooltip, etc. Since we aren’t using those components, let’s exclude them from our built stylesheets. Keeping the `}<inlineCode parentName="p">{`$feature-flags`}</inlineCode>{` Sass map, replace the `}<inlineCode parentName="p">{`styles.scss`}</inlineCode>{` import with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss",
        "metastring": "path=src/index.scss",
        "path": "src/index.scss"
      }}>{`// Feature flags
$css--font-face: true;
$css--plex: true;

// Global styles
@import 'carbon-components/scss/globals/scss/css--font-face';
@import 'carbon-components/scss/globals/grid/grid';

// Carbon components
@import 'carbon-components/scss/components/breadcrumb/breadcrumb';
@import 'carbon-components/scss/components/button/button';
@import 'carbon-components/scss/components/data-table/data-table';
@import 'carbon-components/scss/components/link/link';
@import 'carbon-components/scss/components/pagination/pagination';
@import 'carbon-components/scss/components/tabs/tabs';
@import 'carbon-components/scss/components/ui-shell/ui-shell';
`}</code></pre>
    <p>{`In comparing to the included `}<inlineCode parentName="p">{`carbon-components/scss/globals/scss/styles.scss`}</inlineCode>{`, you may be asking what happened to importing `}<inlineCode parentName="p">{`_vars.scss`}</inlineCode>{`, `}<inlineCode parentName="p">{`_colors.scss`}</inlineCode>{`, `}<inlineCode parentName="p">{`_theme.scss`}</inlineCode>{`, etc.?`}</p>
    <p>{`Many of those global Sass partials get imported through the components. For example, open `}<inlineCode parentName="p">{`node_modules/carbon-components/scss/components/button/_button.scss`}</inlineCode>{` to see its dependencies. No harm in importing them as `}<inlineCode parentName="p">{`carbon-components/scss/globals/scss/styles.scss`}</inlineCode>{` does, but for simplicity here, we’ll let the components pull them in.`}</p>
    <p>{`You can read more about optimizing Carbon’s Sass in the `}<a parentName="p" {...{
        "href": "https://medium.com/carbondesign/minimal-css-with-carbon-b0c089ccfa71"
      }}>{`Carbon Design System publication`}</a>{` on Medium.`}</p>
    <h2 {...{
      "id": "build-for-production"
    }}>{`Build for production`}</h2>
    <p>{`Before we deploy our app, we need to create an optimized production build with this command. You may need to `}<inlineCode parentName="p">{`CTRL-C`}</inlineCode>{` to stop the development environment first.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn build
`}</code></pre>
    <p>{`Looking at `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`, you’ll find `}<inlineCode parentName="p">{`yarn build`}</inlineCode>{` to run `}<inlineCode parentName="p">{`react-scripts build`}</inlineCode>{`. This builds the app for production to the `}<inlineCode parentName="p">{`build`}</inlineCode>{` folder. It bundles React in production mode and optimizes the build for the best performance. It even goes so far to minify files and include hashes in filenames for caching.`}</p>
    <p>{`As a lot of this may seem like magic since the build configuration came from Create React App, go ahead and check out their `}<a parentName="p" {...{
        "href": "https://facebook.github.io/create-react-app/docs/production-build"
      }}>{`production build guidelines`}</a>{` for a full description of what’s happening.`}</p>
    <h2 {...{
      "id": "create-manifest-file"
    }}>{`Create manifest file`}</h2>
    <p>{`Now that we have a production build, let’s get it on the cloud. We’re going to use `}<a parentName="p" {...{
        "href": "https://github.com/cloudfoundry/staticfile-buildpack.git"
      }}>{`staticfile-buildpack`}</a>{` to deploy our webapp. Since this is a Cloud Foundry buildpack, we’ll be using the `}<inlineCode parentName="p">{`cf`}</inlineCode>{` command line interface (CLI). If running `}<inlineCode parentName="p">{`cf --help`}</inlineCode>{` doesn’t work for you, chances are you need to `}<a parentName="p" {...{
        "href": "https://docs.cloudfoundry.org/cf-cli/install-go-cli.html"
      }}>{`install the CLI`}</a>{`.`}</p>
    <p><em parentName="p">{`Note: If unfamiliar with buildpacks, the `}<a parentName="em" {...{
          "href": "https://docs.cloudfoundry.org/buildpacks/staticfile/index.html"
        }}>{`staticfile buildpack docs`}</a>{` has good definitions and configuration documentation.`}</em></p>
    <p>{`With the Cloud Foundry CLI installed, next, we need to create a `}<inlineCode parentName="p">{`manifest.yml`}</inlineCode>{` file in the root of the project. To prevent multiple apps trying to use the `}<inlineCode parentName="p">{`carbon-tutorial`}</inlineCode>{` name, replace `}<inlineCode parentName="p">{`USERNAME`}</inlineCode>{` with your GitHub username below to make sure our apps are uniquely named.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash",
        "metastring": "path=manifest.yml",
        "path": "manifest.yml"
      }}>{`---
applications:
  - name: carbon-tutorial-USERNAME
    memory: 64M
    buildpack: https://github.com/cloudfoundry/staticfile-buildpack.git
`}</code></pre>
    <p><em parentName="p">{`Note: With this set-up we’re still using a GitHub personal access token saved in `}</em><inlineCode parentName="p">{`.env.local`}</inlineCode><em parentName="p">{`. If you haven’t created a GitHub access token yet, see `}<a parentName="em" {...{
          "href": "/tutorial/react/step-3#create-access-token"
        }}>{`step 3`}</a>{`. You can put the environment variable in the manifest file, or manually add it in the IBM Cloud dashboard, but since we’re building off previous tutorial steps nothing more is needed.`}</em></p>
    <h2 {...{
      "id": "create-static-file"
    }}>{`Create static file`}</h2>
    <p>{`Create a new static file in the root of the project named `}<inlineCode parentName="p">{`Staticfile`}</inlineCode>{`. This tells the app to deploy from the `}<inlineCode parentName="p">{`build`}</inlineCode>{` folder and not the root of the project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash",
        "metastring": "path=Staticfile",
        "path": "Staticfile"
      }}>{`root: build
`}</code></pre>
    <h3 {...{
      "id": "cloud-foundry-ignore"
    }}>{`Cloud Foundry ignore`}</h3>
    <p>{`After telling Cloud Foundry what to include, we can also specify what to ignore. Create a top-level `}<inlineCode parentName="p">{`.cfignore`}</inlineCode>{` file. Cloud Foundry doesn’t let you push read-only files (specifically, files with permissions <`}<inlineCode parentName="p">{`400`}</inlineCode>{`), so to prevent issues with the deploy, add:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash",
        "metastring": "path=.cfignore",
        "path": ".cfignore"
      }}>{`node_modules/.cache
`}</code></pre>
    <p>{`You can speed up deploys by decreasing the files uploaded through cloud foundry. To accomplish this, ignore any folder not required by the production application on IBM Cloud. For example, in the case of serving static files, you can ignore `}<inlineCode parentName="p">{`node_modules/`}</inlineCode>{` and `}<inlineCode parentName="p">{`src/`}</inlineCode>{` because the only folder being served is `}<inlineCode parentName="p">{`build/`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "deploy-app"
    }}>{`Deploy app`}</h2>
    <p>{`Login to IBM Cloud with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ cf login -a https://api.ng.bluemix.net -sso
`}</code></pre>
    <p>{`Deploy app using the `}<inlineCode parentName="p">{`cf push`}</inlineCode>{` command. Since `}<inlineCode parentName="p">{`manifest.yml`}</inlineCode>{` is in our root directory, we don’t need to specify it in the push command. But, if you have multiple manifest files that target different environments, it’s good practice to specify the file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ cf push -f manifest.yml
`}</code></pre>
    <p>{`To make it easy on ourselves by not needing to remember that command, let’s add a script in `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`. We can combine the build and deploy steps to make sure we only deploy immediately after running the build. In the `}<inlineCode parentName="p">{`"scripts"`}</inlineCode>{` object in `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`, add:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash",
        "metastring": "path=package.json",
        "path": "package.json"
      }}>{`"deploy": "rm -rf ./build && yarn build && cf push -f manifest.yml"
`}</code></pre>
    <p>{`Next time you want to deploy, you can simply run `}<inlineCode parentName="p">{`yarn deploy`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "submit-pull-request"
    }}>{`Submit pull request`}</h2>
    <p>{`That does it! We’re going to submit a pull request to verify completion of this tutorial step. In doing so, `}<strong parentName="p">{`please include the mybluemix.net URL for your deployed app in your pull request description`}</strong>{`.`}</p>
    <h3 {...{
      "id": "continuous-integration-ci-check"
    }}>{`Continuous integration (CI) check`}</h3>
    <p>{`Run the CI check to make sure we’re all set to submit a pull request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn ci-check
`}</code></pre>
    <p><em parentName="p">{`Note: Having issues running the CI check? `}<a parentName="em" {...{
          "href": "/tutorial/react/step-1#continuous-integration-(ci)-check"
        }}>{`Step 1`}</a>{` has troubleshooting notes that may help.`}</em></p>
    <h3 {...{
      "id": "git-commit-and-push"
    }}>{`Git commit and push`}</h3>
    <p>{`Before we can create a pull request, stage and commit all of your changes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git add --all && git commit -m "feat(tutorial): complete step 5"
`}</code></pre>
    <p>{`Then, push to your repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git push origin react-step-5
`}</code></pre>
    <p><em parentName="p">{`Note: Having issues pushing your changes? `}<a parentName="em" {...{
          "href": "/tutorial/react/step-1#git-commit-and-push"
        }}>{`Step 1`}</a>{` has troubleshooting notes that may help.`}</em></p>
    <h3 {...{
      "id": "pull-request-pr"
    }}>{`Pull request (PR)`}</h3>
    <p>{`Finally, visit `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial"
      }}>{`carbon-tutorial`}</a>{` to “Compare & pull request”. In doing so, make sure that you are comparing to `}<inlineCode parentName="p">{`react-step-5`}</inlineCode>{` into `}<inlineCode parentName="p">{`base: react-step-5`}</inlineCode>{`.`}</p>
    <p><em parentName="p">{`Note: Expect your tutorial step PRs to be reviewed by the Carbon team but not merged. We’ll close your PR so we can keep the repository’s remote branches pristine and ready for the next person!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      